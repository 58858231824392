export const PubliclyAvailableLLMModels = [
  "gpt-4o",
  "gpt-4o-mini",
  "claude-3-5-sonnet",
] as const;
export const RestrictedLLMModels = [
  "gemini-1.5-flash",
  "gemini-1.5-pro",
  "gemini-2.0-flash",
  "gemini-2.0-flash-lite",
  "gemini-2.0-pro",
  "gemini-2.0-flash-thinking",
  "claude-3-haiku",
  "claude-3-sonnet",
  "gpt-4o-test",
  "gpt-4o-mini-test",
  "formx-self-host-llm",
  "qwen2-vl-7b-instruct",
  "minicpm-v-2_6",
] as const;
export const LLMModelsWithVision = [
  "claude-3-haiku",
  "claude-3-sonnet",
  "claude-3-5-sonnet",
  "gpt-4o",
  "gpt-4o-test",
  "gpt-4o-mini",
  "gpt-4o-mini-test",
  "gemini-1.5-flash",
  "gemini-1.5-pro",
  "gemini-2.0-flash",
  "gemini-2.0-pro",
  "gemini-2.0-flash-lite",
  "gemini-2.0-flash-thinking",
  "qwen2-vl-7b-instruct",
  "minicpm-v-2_6",
];
export const AllLLMModels = [
  ...PubliclyAvailableLLMModels,
  ...RestrictedLLMModels,
] as const;

export const DefaultLLMModel = "gpt-4o-mini" as const;

export const LLMModelNameKey: Record<(typeof AllLLMModels)[number], string> = {
  "gemini-1.5-flash": "llm_model.gemini-1.5-flash",
  "gemini-1.5-pro": "llm_model.gemini-1.5-pro",
  "gemini-2.0-flash": "llm_model.gemini-2.0-flash",
  "gemini-2.0-pro": "llm_model.gemini-2.0-pro",
  "gemini-2.0-flash-lite": "llm_model.gemini-2.0-flash-lite",
  "gemini-2.0-flash-thinking": "llm_model.gemini-2.0-flash-thinking",
  "claude-3-haiku": "llm_model.claude-3-haiku",
  "claude-3-sonnet": "llm_model.claude-3-sonnet",
  "claude-3-5-sonnet": "llm_model.claude-3-5-sonnet",
  "gpt-4o": "llm_model.gpt-4o",
  "gpt-4o-test": "llm_model.gpt-4o-test",
  "gpt-4o-mini": "llm_model.gpt-4o-mini",
  "gpt-4o-mini-test": "llm_model.gpt-4o-mini-test",
  "formx-self-host-llm": "llm_model.formx-self-host-llm",
  "qwen2-vl-7b-instruct": "llm_model.qwen2-vl-7b-instruct",
  "minicpm-v-2_6": "llm_model.minicpm-v-2_6",
};
