import classnames from "classnames";
import * as React from "react";

import styles from "./styles.module.scss";

interface FSLSplitViewProps {
  left: React.ReactNode;
  right: React.ReactNode;
  rightVisible: boolean;
  leftMaxWidth?: number;
  leftMinWidth?: number;
  rightMaxWidth?: number;
  rightMinWidth?: number;
  className?: string;
  isDividerVisible?: boolean;
}

export function FSLSplitView(props: FSLSplitViewProps) {
  const rightMaxWidth = props.rightMaxWidth;
  const rightMinWidth = props.rightMinWidth ?? 0;
  const leftMaxWidth = props.leftMaxWidth;
  const leftMinWidth = props.leftMinWidth ?? 0;
  const { className } = props;
  const isDividerVisible = props.isDividerVisible ?? false;

  return (
    <div className={classnames(styles["split-view"], className)}>
      <div
        className={styles["split-view-left"]}
        style={{ maxWidth: leftMaxWidth, minWidth: leftMinWidth }}
      >
        {props.left}
      </div>
      {props.rightVisible && (
        <>
          {isDividerVisible && <div className={styles["split-view-divider"]} />}
          <div
            className={styles["split-view-right"]}
            style={{ maxWidth: rightMaxWidth, minWidth: rightMinWidth }}
          >
            {props.right}
          </div>
        </>
      )}
    </div>
  );
}
