import { produce } from "immer";
import * as React from "react";
import { useNavigate } from "react-router";
import { v4 as uuidv4 } from "uuid";

import { useCustomModelActionCreator } from "../actions/customModel";
import { useExtractorActionCreator } from "../actions/extractor";
import { useFormActionCreator } from "../actions/form";
import { useFormGroupActionCreator } from "../actions/formGroup";
import {
  ExtractorCreateModalResponseType,
  ExtractorType,
  useExtractorCreateModalHandle,
} from "../components/ExtractorCreateModal";
import { DefaultCustomModel } from "../constants";
import { EXTRACTED_CONTENT_SCHEMA_TEMPLATE } from "../constants/extractedContentSchemaTemplate";
import {
  TemplatedInstantModelExtractorDefinition,
  isPrebuiltTemplatedExtractors,
  isTemplatedInstantModelExtractors,
} from "../constants/templatedInstantModelExtractor";
import { FOCRError } from "../errors";
import { UploadFileKeeperService } from "../services/uploadFileKeeper";
import { CustomModel } from "../types/customModel";
import { CustomModelPreset } from "../types/customModelPreset";
import {
  ExtractedContentSchema,
  accessExtractedContentSchema,
} from "../types/extractedContentSchema";
import {
  PrebuiltExtractor,
  PrebuiltTemplatedExtractor,
} from "../types/extractor";
import { DetailedForm } from "../types/form";
import { DetailFormGroup, FormGroupType } from "../types/formGroup";
import { ExtractedContentSchemaMapper } from "../types/mappers/customModel";
import { chooseFile } from "../utils/file";
import { rotateAndCompressImage } from "../utils/image";
import { WorkerClient } from "../workerClient";
import { useToast } from "./toast";

function useCreateFormGroup() {
  const toast = useToast();
  const navigate = useNavigate();
  const { createFormGroup } = useFormGroupActionCreator();

  const doCreateFormGroup = React.useCallback(
    async (name: string, type: FormGroupType) => {
      try {
        const formGroup = await createFormGroup(name, type);
        navigate(`/form-group/${formGroup.id}/edit`);
      } catch (e) {
        toast.error("error.fail_to_create_form_group");
      }
    },
    [createFormGroup, navigate, toast]
  );

  return React.useMemo(
    () => ({
      doCreateFormGroup,
    }),
    [doCreateFormGroup]
  );
}

function useCreateForm() {
  const toast = useToast();
  const navigate = useNavigate();
  const { createForm } = useFormActionCreator();

  const doCreateForm = React.useCallback(
    async (formName: string, file?: File) => {
      try {
        const image = file ? await rotateAndCompressImage(file) : undefined;
        const form = await createForm(formName, image);
        navigate(`/form/${form.id}/edit`);
      } catch (e) {
        if (e instanceof FOCRError) {
          toast.error(e.messageId);
        } else {
          toast.error("error.fail_to_create_form");
        }
      }
    },
    [createForm, navigate, toast]
  );

  return React.useMemo(
    () => ({
      doCreateForm,
    }),
    [doCreateForm]
  );
}

export function useCreateCustomModel() {
  const {
    createCustomModel,
    uploadCustomModelPreviewImage,
    updateCustomModelExtractedContentSchema,
  } = useCustomModelActionCreator();
  const toast = useToast();
  const navigate = useNavigate();

  const createCustomModelThenOpenSetup = React.useCallback(
    async (
      name: string,
      selectedPrebuiltCustomModel?: CustomModelPreset,
      query?: Record<string, string>
    ) => {
      const newCustomModel = produce(DefaultCustomModel, draft => {
        draft.name = name;
        draft.config.basedOn = selectedPrebuiltCustomModel;
        draft.config.isFSLModel = true;
      });

      try {
        const createdCustomModel = await createCustomModel(newCustomModel, "");
        toast.success("custom_model_editor.custom_model_is_created");

        const queryString = new URLSearchParams(query ?? {}).toString();
        navigate(`/custom-model/${createdCustomModel.id}/setup?${queryString}`);
        return createdCustomModel;
      } catch {
        toast.error("error.custom_model.fail_to_create_custom_model");
      }
      return undefined;
    },
    [createCustomModel, toast, navigate]
  );

  const createTemplatedInstantCustomModel = React.useCallback(
    async (
      name: string,
      extractorType: keyof typeof EXTRACTED_CONTENT_SCHEMA_TEMPLATE
    ) => {
      const definition =
        TemplatedInstantModelExtractorDefinition[
          extractorType as keyof typeof TemplatedInstantModelExtractorDefinition
        ];
      const newCustomModel = produce(DefaultCustomModel, draft => {
        const extractedContentTemplate = accessExtractedContentSchema(
          EXTRACTED_CONTENT_SCHEMA_TEMPLATE[
            extractorType as keyof typeof EXTRACTED_CONTENT_SCHEMA_TEMPLATE
          ] as ExtractedContentSchema
        )
          .randomizeIds()
          .updateTimestamp().data;
        draft.name = name;
        draft.config.isFSLModel = true;
        draft.config.extractedContentSchema = extractedContentTemplate;
        draft.config.llmParameters = {
          should_preserve_horizontal_whitespace: true,
        };

        if (definition?.llmParameters) {
          draft.config.llmParameters = definition.llmParameters;
        }

        draft.config.documentTypeForSample = extractorType;
      });

      return createCustomModel(newCustomModel, "", definition.formConfig);
    },
    [createCustomModel]
  );

  const createCustomModelByDetectionThenOpenSetup = React.useCallback(
    async (token: string, file: File, query?: Record<string, string>) => {
      const workerClient = new WorkerClient(token);

      const { extracted_content_schema } =
        await workerClient.detectExtractedContentSchema(file);

      const extractedContentSchema = ExtractedContentSchemaMapper.fromResp(
        extracted_content_schema
      );

      if (!extractedContentSchema) {
        return;
      }

      const newCustomModel = produce(DefaultCustomModel, draft => {
        draft.name = extractedContentSchema.name;
        draft.config.isFSLModel = true;
        draft.config.extractedContentSchema = accessExtractedContentSchema(
          extractedContentSchema
        )
          .randomizeIds()
          .updateTimestamp().data;
      });

      const createdCustomModel = await createCustomModel(newCustomModel, "");

      const previewImage = await uploadCustomModelPreviewImage(
        createdCustomModel.id,
        file
      );
      await updateCustomModelExtractedContentSchema(
        createdCustomModel.config.extractedContentSchema,
        previewImage
      );

      toast.success("custom_model_editor.custom_model_is_created");
      const queryString = new URLSearchParams(query ?? {}).toString();
      navigate(`/custom-model/${createdCustomModel.id}/setup?${queryString}`);
      return createdCustomModel;
    },
    [
      createCustomModel,
      navigate,
      toast,
      uploadCustomModelPreviewImage,
      updateCustomModelExtractedContentSchema,
    ]
  );

  return React.useMemo(
    () => ({
      createCustomModelThenOpenSetup,
      createTemplatedInstantCustomModel,
      createCustomModelByDetectionThenOpenSetup,
    }),
    [
      createCustomModelThenOpenSetup,
      createTemplatedInstantCustomModel,
      createCustomModelByDetectionThenOpenSetup,
    ]
  );
}

export function useCreatePrebuiltExtractor() {
  const { createPrebuiltExtractor } = useExtractorActionCreator();

  const toast = useToast();
  const navigate = useNavigate();

  const createPrebuiltExtractorThenOpenLink = React.useCallback(
    async (name: string, type: PrebuiltExtractor) => {
      try {
        const { form, formGroup } = await createPrebuiltExtractor(name, type);

        if (form) {
          navigate(`/form/${form.id}/test`);
          return form;
        } else if (formGroup) {
          navigate(`/form-group/${formGroup.id}/edit`);
          return formGroup;
        }
      } catch {
        toast.error("error.fail_to_create_extractor");
      }
      return undefined;
    },
    [createPrebuiltExtractor, navigate, toast]
  );

  const createPrebuiltExtractorThenTest = React.useCallback(
    async (
      name: string,
      type: PrebuiltExtractor,
      file: File,
      query?: { [key: string]: string }
    ) => {
      try {
        const { form, formGroup } = await createPrebuiltExtractor(name, type);
        const key = uuidv4().slice(0, 8); // using a short key to avoid long url

        UploadFileKeeperService.getInstance().setFile(key, file);
        const queryItems = {
          file: key,
          ...query,
        };
        const queryString = new URLSearchParams(queryItems).toString();

        if (form) {
          navigate(`/form/${form.id}/test?${queryString}`);
          return form;
        } else if (formGroup) {
          navigate(`/form-group/${formGroup.id}/test?${queryString}`);
          return formGroup;
        }
      } catch {
        toast.error("error.fail_to_create_extractor");
      }
      return undefined;
    },
    [createPrebuiltExtractor, navigate, toast]
  );

  return React.useMemo(
    () => ({
      createPrebuiltExtractorThenTest,
      createPrebuiltExtractorThenOpenLink,
    }),
    [createPrebuiltExtractorThenOpenLink, createPrebuiltExtractorThenTest]
  );
}

/* Helper hooks to create prebuilt extractor (stored in the backend)
  or instant custom model with template (stored in frontend)
 */
export function useCreatePrebuiltTemplatedExtractor() {
  const { createPrebuiltExtractor } = useExtractorActionCreator();
  const { createTemplatedInstantCustomModel } = useCreateCustomModel();

  const createPrebuiltTemplatedExtractor = React.useCallback(
    async (name: string, extractorType: PrebuiltTemplatedExtractor) => {
      const result: {
        form: DetailedForm | undefined;
        formGroup: DetailFormGroup | undefined;
        customModel: CustomModel | undefined;
      } = {
        form: undefined,
        formGroup: undefined,
        customModel: undefined,
      };

      if (isTemplatedInstantModelExtractors(extractorType)) {
        result.customModel = await createTemplatedInstantCustomModel(
          name,
          extractorType as keyof typeof EXTRACTED_CONTENT_SCHEMA_TEMPLATE
        );
      } else {
        const prebuiltResutl = await createPrebuiltExtractor(
          name,
          extractorType
        );
        result.form = prebuiltResutl.form;
        result.formGroup = prebuiltResutl.formGroup;
      }
      return result;
    },
    [createPrebuiltExtractor, createTemplatedInstantCustomModel]
  );

  const navigate = useNavigate();

  const createPrebuiltTemplatedExtractorThenOpenLink = React.useCallback(
    async (name: string, extractorType: PrebuiltTemplatedExtractor) => {
      const { form, formGroup, customModel } =
        await createPrebuiltTemplatedExtractor(name, extractorType);

      if (form) {
        navigate(`/form/${form.id}/test`);
        return form;
      } else if (formGroup) {
        navigate(`/form-group/${formGroup.id}/edit`);
        return formGroup;
      } else if (customModel) {
        navigate(`/custom-model/${customModel.id}/setup`);
        return customModel;
      } else {
        return undefined;
      }
    },
    [navigate, createPrebuiltTemplatedExtractor]
  );

  const createPrebuiltTemplatedExtractorThenTest = React.useCallback(
    async (
      name: string,
      extractorType: PrebuiltTemplatedExtractor,
      file?,
      query?: { [key: string]: string }
    ) => {
      const { form, formGroup, customModel } =
        await createPrebuiltTemplatedExtractor(name, extractorType);

      let queryString = "";
      if (file) {
        const key = uuidv4().slice(0, 8); // using a short key to avoid long url
        UploadFileKeeperService.getInstance().setFile(key, file);
        const queryItems = {
          file: key,
          ...query,
        };
        queryString = "?" + new URLSearchParams(queryItems).toString();
      }

      if (form) {
        navigate(`/form/${form.id}/test${queryString}`);
        return form;
      } else if (formGroup) {
        navigate(`/form-group/${formGroup.id}/test${queryString}`);
        return formGroup;
      } else if (customModel) {
        navigate(`/custom-model/${customModel.id}/test${queryString}`);
        return customModel;
      } else {
        return undefined;
      }
    },
    [navigate, createPrebuiltTemplatedExtractor]
  );

  return React.useMemo(
    () => ({
      createPrebuiltTemplatedExtractor,
      createPrebuiltTemplatedExtractorThenOpenLink,
      createPrebuiltTemplatedExtractorThenTest,
    }),
    [
      createPrebuiltTemplatedExtractor,
      createPrebuiltTemplatedExtractorThenOpenLink,
      createPrebuiltTemplatedExtractorThenTest,
    ]
  );
}

export function useCreateExtractor() {
  const [isCreatingExtractor, setIsCreatingExtractor] = React.useState(false);
  const extractorCreateModalHandle = useExtractorCreateModalHandle();

  const { doCreateForm } = useCreateForm();
  const { doCreateFormGroup } = useCreateFormGroup();
  const { createCustomModelThenOpenSetup } = useCreateCustomModel();
  const { createPrebuiltTemplatedExtractorThenTest } =
    useCreatePrebuiltTemplatedExtractor();

  const requestToCreateExtractor = React.useCallback(
    async (
      type: ExtractorType | PrebuiltTemplatedExtractor,
      nameDefaultValue?: string
    ) => {
      const response = await extractorCreateModalHandle.methods.open(
        type,
        nameDefaultValue
      );

      if (
        response.type === ExtractorCreateModalResponseType.Cancelled ||
        response.accpetedValue === undefined
      ) {
        return;
      }

      const { name, formGroupType, selectedPrebuiltCustomModel } =
        response.accpetedValue;

      setIsCreatingExtractor(true);

      if (isPrebuiltTemplatedExtractors(type)) {
        createPrebuiltTemplatedExtractorThenTest(name, type).then(() => {
          setIsCreatingExtractor(false);
        });
        return;
      }

      switch (type) {
        case "form":
          doCreateForm(name).then(() => {
            setIsCreatingExtractor(false);
          });
          break;
        case "form_group":
          if (formGroupType)
            doCreateFormGroup(name, formGroupType).then(() => {
              setIsCreatingExtractor(false);
            });
          break;
        case "custom_model":
          createCustomModelThenOpenSetup(
            name,
            selectedPrebuiltCustomModel
          ).then(() => {
            setIsCreatingExtractor(false);
          });
          break;
      }
    },
    [
      createPrebuiltTemplatedExtractorThenTest,
      createCustomModelThenOpenSetup,
      doCreateForm,
      doCreateFormGroup,
      extractorCreateModalHandle.methods,
    ]
  );

  return React.useMemo(
    () => ({
      isCreatingExtractor,
      requestToCreateExtractor,
      extractorCreateModalHandle,
    }),
    [isCreatingExtractor, requestToCreateExtractor, extractorCreateModalHandle]
  );
}

export function useImportExtractor() {
  const [isImportingExtractor, setIsImportingExtractor] = React.useState(false);
  const { importExtractor } = useExtractorActionCreator();
  const toast = useToast();
  const navigate = useNavigate();

  const onImportExtractor = React.useCallback(async () => {
    try {
      const files = await chooseFile("application/zip");
      if (!files || files.length === 0) return;
      setIsImportingExtractor(true);
      const result = await importExtractor(files[0]);

      if ("form" in result) {
        navigate(`/form/${result.form.id}/edit`);
      } else if ("form_group" in result) {
        navigate(`/form-group/${result.form_group.id}/edit`);
      } else if ("custom_model" in result) {
        navigate(`/custom-model/${result.custom_model.id}/setup`);
      }
    } catch {
      toast.error("error.fail_to_import_extractor");
    } finally {
      setIsImportingExtractor(false);
    }
  }, [importExtractor, navigate, toast]);

  return React.useMemo(() => {
    return {
      isImportingExtractor,
      onImportExtractor,
    };
  }, [isImportingExtractor, onImportExtractor]);
}
