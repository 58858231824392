import imageCompression from "browser-image-compression";

import { MAX_ALLOWED_FORM_IMAGE_SIZE_IN_BYTES } from "../constants";
import errors from "../errors";
import { Dimension } from "../models";

const mimeExtensions: Partial<Record<string, string>> = {
  "image/png": ".png",
  "image/jpeg": ".jpg",
  "application/pdf": ".pdf",
};

export async function imageURLToFile(
  url: string,
  basename: string = "sample"
): Promise<File> {
  const resp = await fetch(url);
  const blob = await resp.blob();
  const contentType =
    resp.headers.get("Content-Type") ?? "application/octet-stream";

  return new File([blob], basename + (mimeExtensions[contentType] ?? ""), {
    type: contentType,
  });
}

export function getImageSizeFromFile(file: File): Promise<Dimension> {
  if (file.size > MAX_ALLOWED_FORM_IMAGE_SIZE_IN_BYTES) {
    return Promise.reject(errors.FormImageSizeTooLarge);
  }

  return new Promise<Dimension>((resolve, reject) => {
    const objectUrl = URL.createObjectURL(file);
    const image = new Image();

    image.addEventListener("load", () => {
      resolve({
        width: image.width,
        height: image.height,
      });
    });

    image.addEventListener("error", () => {
      reject(errors.CannotGetImageDimension);
    });
    image.src = objectUrl;
  });
}

export async function rotateAndCompressImage(image: File): Promise<File> {
  const resizedBlob = await imageCompression(image, {
    maxWidthOrHeight: 2048,
    useWebWorker: true,
  });

  return new File([resizedBlob], image.name, {
    type: resizedBlob.type,
  });
}
