import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IDialogContentProps,
  ISpinnerStyles,
  PrimaryButton,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import cn from "classnames";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useCustomModelActionCreator } from "../../actions/customModel";
import { SUPPORTED_IMAGE_MIME } from "../../constants";
import { useFSLCustomModelEditor } from "../../contexts/fslCustomModelEditor";
import { useLocale } from "../../contexts/locale";
import { FOCRError } from "../../errors";
import { useWorkerToken } from "../../hooks/app";
import {
  useDragAndDropDefaultOptions,
  useDragAndDropFiles,
} from "../../hooks/drag_and_drop";
import { readFileAsImageDataUris } from "../../hooks/extract_test";
import { useToast } from "../../hooks/toast";
import { CustomModel } from "../../types/customModel";
import { accessExtractedContentSchema } from "../../types/extractedContentSchema";
import { ExtractedContentSchemaMapper } from "../../types/mappers/customModel";
import { chooseFile } from "../../utils/file";
import { imageURLToFile } from "../../utils/image";
import { WorkerClient } from "../../workerClient";
import { DangerButton } from "../DangerButton";
import { ImageViewer } from "../ImageViewer";
import { PageHeader } from "../PageHeader";

interface FSLReferenceImageViewerProps {
  className?: string;
  isEditingSchema: boolean;
}

interface UploaderProps {
  className?: string;
  hasSchema: boolean;
  onRequestUploadFile: () => void;
  onUploadFile: (file: File) => void;
}

function Uploader(props: UploaderProps) {
  const { className, hasSchema, onRequestUploadFile, onUploadFile } = props;

  const dragAndDropDefaultOptions = useDragAndDropDefaultOptions();

  const isFileOver = dragAndDropDefaultOptions.isFileOver;

  useDragAndDropFiles(
    (files?: File[]) => {
      const file = files?.[0];
      if (file != null) {
        onUploadFile(file);
      }
    },
    supportedReferenceImageMimeTypes,
    undefined,
    dragAndDropDefaultOptions.options
  );

  const handleUploadOnClick = useCallback(() => {
    onRequestUploadFile();
  }, [onRequestUploadFile]);

  return (
    <div className={cn(className, "p-5", isFileOver && "bg-primary-50")}>
      <div
        className={cn(
          "w-full h-full",
          "border-dashed rounded-sm",
          isFileOver ? "border-primary-500" : "border-gray-600",
          "flex items-center justify-center p-5"
        )}
      >
        <div className="flex flex-col items-center text-center">
          <p className="text-xl font-semibold">
            {hasSchema ? (
              <FormattedMessage id="fsl_reference_image_viewer.placeholder.has_schema.title" />
            ) : (
              <FormattedMessage id="fsl_reference_image_viewer.placeholder.no_schema.title" />
            )}
          </p>
          {hasSchema ? null : (
            <p className="text-base font-normal text-primary-600 mt-1">
              <FormattedMessage id="fsl_reference_image_viewer.placeholder.no_schema.subtitle" />
            </p>
          )}
          <PrimaryButton className="mt-4" onClick={handleUploadOnClick}>
            <FormattedMessage id="fsl_reference_image_viewer.placeholder.upload" />
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
}

interface UploadingPlaceholderProps {
  className?: string;
}

function UploadingPlaceholder(props: UploadingPlaceholderProps) {
  const { className } = props;

  const spinnerStyles = useMemo<ISpinnerStyles>(
    () => ({
      circle: {
        width: 40,
        height: 40,
      },
    }),
    []
  );

  return (
    <div className={cn(className, "p-5 flex items-center justify-center")}>
      <div className="flex flex-col items-center text-center">
        <Spinner styles={spinnerStyles} size={SpinnerSize.large} />
        <p className="text-lg font-semibold text-primary-500 mt-2">
          <FormattedMessage id="fsl_reference_image_viewer.placeholder.uploading" />
        </p>
      </div>
    </div>
  );
}

interface ReplaceFieldsConfirmDialogProps {
  isOpened: boolean;
  onDismiss: () => void;
  onConfirm: (keepFields: boolean) => void;
}
function ReplaceFieldsConfirmDialog(props: ReplaceFieldsConfirmDialogProps) {
  const { isOpened, onDismiss, onConfirm } = props;
  const { localized } = useLocale();

  const dialogContentProps: IDialogContentProps = useMemo(
    () => ({
      type: DialogType.close,
      title: localized("fsl_reference_image_viewer.replace_fields.title"),
      subText: localized("fsl_reference_image_viewer.replace_fields.sub_text"),
    }),
    [localized]
  );

  const handleKeepFieldsOnClick = useCallback(
    () => onConfirm(true),
    [onConfirm]
  );
  const handleReplaceFieldsOnClick = useCallback(
    () => onConfirm(false),
    [onConfirm]
  );

  return (
    <Dialog
      hidden={!isOpened}
      minWidth={460}
      onDismiss={onDismiss}
      dialogContentProps={dialogContentProps}
    >
      <DialogFooter>
        <DefaultButton
          text={localized(
            "fsl_reference_image_viewer.replace_fields.keep_fields"
          )}
          onClick={handleKeepFieldsOnClick}
        />
        <DangerButton
          text={localized(
            "fsl_reference_image_viewer.replace_fields.replace_fields"
          )}
          onClick={handleReplaceFieldsOnClick}
        />
      </DialogFooter>
    </Dialog>
  );
}

async function extractContentSchema(workerToken: string, file: File) {
  const workerClient = new WorkerClient(workerToken);

  const { extracted_content_schema } =
    await workerClient.detectExtractedContentSchema(file);

  const extractedContentSchema = ExtractedContentSchemaMapper.fromResp(
    extracted_content_schema
  );
  if (extractedContentSchema != null) {
    return accessExtractedContentSchema(extractedContentSchema)
      .randomizeIds()
      .updateTimestamp().data;
  }
  return undefined;
}

async function loadImagesFromURL(url: string) {
  const file = await imageURLToFile(url);
  const images = await readFileAsImageDataUris(file);
  return images;
}

export function useFSLReferenceImageViewerState(
  props: FSLReferenceImageViewerProps
) {
  const { customModel, backToMain } = useFSLCustomModelEditor();
  const toast = useToast();
  const { token } = useWorkerToken();
  const {
    updateCustomModelExtractedContentSchema,
    uploadCustomModelPreviewImage,
  } = useCustomModelActionCreator();

  const hasImage = customModel?.config.previewImage != null;
  const hasSchema =
    (customModel?.config.extractedContentSchema?.payload ?? []).length > 0;
  const imageURL = customModel?.config.previewImage?.url ?? null;
  const filename = customModel?.config.previewImage?.filename ?? null;

  const [viewerSrcs, setViewerSrcs] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (imageURL == null) {
      return;
    }

    let cancelled = false;
    setIsLoading(true);
    loadImagesFromURL(imageURL)
      .then(images => {
        if (!cancelled) {
          setIsLoading(false);
          setViewerSrcs(images);
        }
      })
      .catch(error => {
        if (!cancelled) {
          setIsLoading(false);
          toast.error(
            error instanceof FOCRError ? error.messageId : "error.unknown_error"
          );
        }
      });

    return () => {
      setIsLoading(false);
      cancelled = true;
    };
  }, [imageURL, toast]);

  const [replaceFieldConfirmDialogProps, setReplaceFieldConfirmDialogProps] =
    useState<ReplaceFieldsConfirmDialogProps | null>(null);
  const [uploadingFileName, setUploadingFilename] = useState<string | null>(
    null
  );
  const uploadReferenceImage = useCallback(
    async (file: File) => {
      async function doUpload(
        workerToken: string,
        customModel: CustomModel,
        file: File,
        keepFields: boolean = false
      ) {
        setUploadingFilename(file.name);
        try {
          let extractedContentSchema =
            customModel.config.extractedContentSchema;
          if (!keepFields) {
            extractedContentSchema = await extractContentSchema(
              workerToken,
              file
            );
          }

          const previewImage = await uploadCustomModelPreviewImage(
            customModel.id,
            file
          );

          await updateCustomModelExtractedContentSchema(
            extractedContentSchema,
            previewImage
          );
          backToMain();
        } catch (error) {
          toast.error(
            error instanceof FOCRError ? error.messageId : "error.unknown_error"
          );
        } finally {
          setUploadingFilename(null);
        }
      }

      function dismissDialog() {
        setReplaceFieldConfirmDialogProps(
          props => props && { ...props, isOpened: false }
        );
      }

      if (hasSchema) {
        setReplaceFieldConfirmDialogProps({
          isOpened: true,
          onDismiss: () => dismissDialog(),
          onConfirm: keepFields => {
            dismissDialog();
            if (token != null && customModel != null) {
              doUpload(token, customModel, file, keepFields);
            }
          },
        });
      } else {
        if (token != null && customModel != null) {
          doUpload(token, customModel, file);
        }
      }
    },
    [
      backToMain,
      customModel,
      hasSchema,
      toast,
      token,
      updateCustomModelExtractedContentSchema,
      uploadCustomModelPreviewImage,
    ]
  );

  return {
    ...props,
    hasImage,
    hasSchema,
    filename: uploadingFileName ?? filename,
    viewerSrcs,
    isLoading,
    isUploading: uploadingFileName != null,
    uploadReferenceImage,
    replaceFieldConfirmDialogProps,
  };
}

const supportedReferenceImageMimeTypes = [
  ...SUPPORTED_IMAGE_MIME,
  "application/pdf",
];

export function _FSLReferenceImageViewer(
  props: ReturnType<typeof useFSLReferenceImageViewerState>
) {
  const {
    className,
    isEditingSchema,
    hasImage,
    hasSchema,
    filename,
    viewerSrcs,
    isLoading,
    uploadReferenceImage,
    isUploading,
    replaceFieldConfirmDialogProps,
  } = props;
  const { localized } = useLocale();

  const requestUserUploadFile = useCallback(() => {
    chooseFile(supportedReferenceImageMimeTypes.join(","), true).then(files => {
      const file = files?.[0];
      if (file != null) {
        uploadReferenceImage(file);
      }
    });
  }, [uploadReferenceImage]);

  const handleReplaceOnClick = useCallback(() => {
    requestUserUploadFile();
  }, [requestUserUploadFile]);

  const handleUploaderOnRequestUploadFile = useCallback(() => {
    requestUserUploadFile();
  }, [requestUserUploadFile]);

  const handleUploaderOnUploadFile = useCallback(
    (file: File) => {
      uploadReferenceImage(file);
    },
    [uploadReferenceImage]
  );

  return (
    <div className={cn(className, "flex flex-col")}>
      <PageHeader
        className="px-5 flex-none"
        title="fsl_reference_image_viewer.title"
        subtitleText={filename ?? ""}
        right={
          filename == null ? null : (
            <DefaultButton
              text={localized("fsl_reference_image_viewer.replace")}
              disabled={isEditingSchema || isUploading}
              onClick={handleReplaceOnClick}
            />
          )
        }
      />
      <div className="flex-1 relative bg-gray-50">
        {isUploading ? (
          <UploadingPlaceholder className="w-full h-full" />
        ) : !hasImage ? (
          <Uploader
            className="w-full h-full"
            hasSchema={hasSchema}
            onRequestUploadFile={handleUploaderOnRequestUploadFile}
            onUploadFile={handleUploaderOnUploadFile}
          />
        ) : isLoading ? (
          <Spinner
            className="absolute inset-0 m-auto"
            size={SpinnerSize.large}
          />
        ) : (
          <ImageViewer
            className="w-full h-full"
            src={viewerSrcs}
            zoomControlEnabled={true}
          />
        )}
      </div>
      {replaceFieldConfirmDialogProps ? (
        <ReplaceFieldsConfirmDialog {...replaceFieldConfirmDialogProps} />
      ) : null}
    </div>
  );
}

export function FSLReferenceImageViewer(props: FSLReferenceImageViewerProps) {
  const state = useFSLReferenceImageViewerState(props);
  return <_FSLReferenceImageViewer {...state} />;
}
