import {
  DirectionalHint,
  IButtonProps,
  ICalloutProps,
  ITeachingBubbleStyles,
  TeachingBubble,
  TeachingBubbleContent,
} from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import { FormattedMessage } from "@oursky/react-messageformat";
import * as React from "react";

import { ButtonId } from "../../constants/buttonids";
import { useFSLCustomModelEditor } from "../../contexts/fslCustomModelEditor";
import { useLocale } from "../../contexts/locale";
import { FSLFieldSchemaList } from "../FSLFieldSchemaList";
import { MissionBar, MissionBarType } from "../MissionBar";
import { PageHeader } from "../PageHeader";
import { DefaultButton } from "../WrappedMSComponents/Buttons";

interface UpdatedMissionBarProps {
  dismissUpdatedMissionBar: () => void;
}

function UpdatedMissionBar(props: UpdatedMissionBarProps) {
  const { dismissUpdatedMissionBar } = props;

  return (
    <MissionBar
      className="-mx-5"
      type={MissionBarType.Warning}
      title="fsl_custom_model.mission_bar.manage_fields.updated"
      right={
        <DefaultButton
          textId="common.dismiss"
          onClick={dismissUpdatedMissionBar}
        />
      }
    />
  );
}

function ConfirmSchemaGuidanceBubble() {
  const { localized } = useLocale();
  const { setOnboardingStep } = useFSLCustomModelEditor();

  const primaryButtonProps = React.useMemo<IButtonProps>(
    () => ({
      children: (
        <FormattedMessage id="fsl_custom_model.field_schema_viewer.guidance.confirm_schema.test_model" />
      ),
      onClick: () => setOnboardingStep("test-model"),
    }),
    [setOnboardingStep]
  );

  const secondaryButtonProps = React.useMemo<IButtonProps>(
    () => ({
      children: (
        <FormattedMessage id="fsl_custom_model.field_schema_viewer.guidance.confirm_schema.edit_schema" />
      ),
      onClick: () => setOnboardingStep("edit-schema"),
    }),
    [setOnboardingStep]
  );

  const styles = React.useMemo<Partial<ITeachingBubbleStyles>>(
    () => ({
      bodyContent: "bg-primary-500 shadow-elevation16",
      secondaryButton: "text-white hover:text-white active:text-white",
    }),
    []
  );

  return (
    <div className="fixed right-6 bottom-6">
      <TeachingBubbleContent
        styles={styles}
        headline={localized(
          "fsl_custom_model.field_schema_viewer.guidance.confirm_schema.headline"
        )}
        primaryButtonProps={primaryButtonProps}
        secondaryButtonProps={secondaryButtonProps}
      >
        <FormattedMessage id="fsl_custom_model.field_schema_viewer.guidance.confirm_schema.body" />
      </TeachingBubbleContent>
    </div>
  );
}

function EditSchemaGuidanceBubble(props: { targetId: string }) {
  const { targetId } = props;
  const { localized } = useLocale();

  const calloutProps = React.useMemo<ICalloutProps>(
    () => ({ directionalHint: DirectionalHint.bottomAutoEdge }),
    []
  );

  return (
    <TeachingBubble
      target={`#${targetId}`}
      calloutProps={calloutProps}
      headline={localized(
        "fsl_custom_model.field_schema_viewer.guidance.edit_schema.headline"
      )}
    >
      <FormattedMessage id="fsl_custom_model.field_schema_viewer.guidance.edit_schema.body" />
    </TeachingBubble>
  );
}

function TestModelGuidanceBubble(props: { targetId: string }) {
  const { targetId } = props;
  const { localized } = useLocale();

  const calloutProps = React.useMemo<ICalloutProps>(
    () => ({ directionalHint: DirectionalHint.bottomAutoEdge }),
    []
  );

  return (
    <TeachingBubble
      target={`#${targetId}`}
      calloutProps={calloutProps}
      headline={localized(
        "fsl_custom_model.field_schema_viewer.guidance.test_model.headline"
      )}
    >
      <FormattedMessage id="fsl_custom_model.field_schema_viewer.guidance.test_model.body" />
    </TeachingBubble>
  );
}

export function FSLFieldSchemaViewer() {
  const {
    extractedContentSchema,
    editSchema,
    onboardingStep,
    fslFieldSchemaViewerHandle,
  } = useFSLCustomModelEditor();
  const { updatedMissionBarVisible, dismissUpdatedMissionBar } =
    fslFieldSchemaViewerHandle.props;

  const editSchemaId = useId();

  return (
    <div className="flex flex-col h-full">
      {updatedMissionBarVisible && (
        <UpdatedMissionBar
          dismissUpdatedMissionBar={dismissUpdatedMissionBar}
        />
      )}

      <PageHeader
        className="px-5 flex-none"
        title="fsl_custom_model.field_schema_viewer.title"
        subtitle="fsl_custom_model.field_schema_viewer.desc"
        right={
          <DefaultButton
            id={editSchemaId}
            textId="fsl_custom_model.field_schema_viewer.edit_schema_button"
            onClick={editSchema}
          />
        }
        dividerVisible={true}
      />
      <div className="px-5 py-2 flex-1 overflow-y-auto">
        <FSLFieldSchemaList
          extractedContentSchema={extractedContentSchema}
          nameVisible={true}
        />
      </div>

      {onboardingStep === "confirm-schema" ? (
        <ConfirmSchemaGuidanceBubble />
      ) : null}
      {onboardingStep === "edit-schema" ? (
        <EditSchemaGuidanceBubble targetId={editSchemaId} />
      ) : null}
      {onboardingStep === "test-model" ? (
        <TestModelGuidanceBubble targetId={ButtonId.TestExtractorTab} />
      ) : null}
    </div>
  );
}
